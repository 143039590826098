<template>
  <div>
    <p class="has-text-weight-bold">{{title}}</p>
    <draggable 
      tag="ul" 
      class="drag-group" 
      v-model="list"
      group="people" 
      ghost-class="drag-ghost" >
      <li 
        class="drag-item" 
        v-for="element in list" 
        :key="element.id">
        {{element.newLabel}}
        <span 
          @click.prevent="handleClick(element)" 
          class="is-clickable has-text-info">
          <b-icon icon="info-circle" />
        </span>
      </li>
    </draggable>
    <ModalForm 
      v-model="form"
      :show="showModal"
      @modal="showModal = !showModal"
      @cancel="handleClose" />
  </div>
</template>

<script>
import ModalForm from "../filas/Modal.vue"
export default {
  props:{
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => `Dimesión`
    }
  },
  components: {
    ModalForm
  },
  data(){
    return {
      showModal: false,
      form : {}
    }
  },
  computed:{
    list:{
      get(){
        return this.value || []
      },
      set(e){
        if(e.length){
          const last = {...e.slice(-1)[0]};
          if(last.type === "number" || last.type === "custom") return;
          // 
          if(!last.newLabel){
            last.newLabel = last.label
          }
          e = [last]
        }
        this.$emit("input", e)
      }
    }
  },
  methods:{
    handleClick(e){
      this.form = JSON.parse( JSON.stringify(e) )
      this.showModal = true
    },
    handleClose(){
      if(this.form.newLabel){
        this.list = [this.form]
      }
    }
  }
}
</script>