<template>
  <modal-base 
    v-model="vShow" 
    title="Opciones de fila"
    @after-enter="onMain"
    @cancel="$emit(`cancel`)">
    <Form 
      v-model="form"
      ref="form"
      @oncancel="onClose"
      @onsuccess="onClose" />
    <template #footer>
      <b-button
        type="is-secondary"
        icon-left="times"
        @click="handleClose"
        >Cerrar</b-button
      >
    </template>
  </modal-base>
</template>
<script>
import Form from "./Form.vue"
export default {
  props:{
    value:{
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: () => false,
    }
  },
  components:{
    Form,
  },
  computed:{
    form :{
      get(){
        return this.value;
      },
      set(e){
        this.$emit("input",e)
      }
    },
    vShow:{
      get(){
        return this.show
      },
      set(e){
        this.$emit("modal", e)
      }
    }
  },
  methods: {
    onMain(){

    },
    onClose(){
      this.vShow = !this.vShow
      this.$emit(`cancel`)
    },
    handleClose(){
      this.$refs.form.onSubmit()
    }
  }
}
</script>