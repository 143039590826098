<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <p class="mb-2">Campo de origen: <strong>{{form.label}}</strong></p>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-12">
        <input-base
          rules="required"
          label="Etiqueta para mostrar"
          maxlength="150"
          v-model="form.newLabel"
          focus
        />
      </div>
      <div class="column is-12" v-if="form.type === `date`">
        <select-base
          rules=""
          label="Resumir por"
          v-model="form.settings.dateAs"
          :options="opciones"
          expanded
        />
      </div>
      <div class="column is-12" v-if="form.uri">
        <select-search-multiple-uri
          label="Filtrar dimensión"
          inline
          :uri="form.uri"
          v-model="form.settings.ids"
          @change="handleChangeSelect"  />
      </div>
    </div>
    <input type="submit" class="is-hidden"/>
  </form-observer>
</template>
<script>
export default {
  props:{
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    },
    opciones(){
      return [
        {id: ``, value: `Días`},
        {id: `week`, value: `Semanas`},
        {id: `month`, value: `Meses`},
        {id: `year`, value: `Años`},
      ]
    }
  },
  watch:{
    'form.settings.dateAs'(e){
      if(e === `week`) return this.form.newLabel = `Semanas de ${this.form.label}` 
      if(e === `month`) return this.form.newLabel = `Meses de ${this.form.label}` 
      if(e === `year`) return this.form.newLabel = `Años de ${this.form.label}` 
      return this.form.newLabel = `${this.form.label}`
    }
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.$emit('onsuccess')
    },
    handleChangeSelect(e){
      this.$set(this.form.settings,"ids", e)
    }
  }
}
</script>