<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <h3 class="has-text-weight-semibold is-size-4 mb-2">
      <b-icon icon="sliders-h"></b-icon>
      Opciones del gráfico
    </h3>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-4">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-12">
            <input-base
              rules="required"
              label="Nombre"
              maxlength="150"
              placeholder="Título del bloque"
              v-model="form.nombre"
              focus
            />
          </div>
          <div class="column is-12">
            <select-base
              rules="required"
              label="Ancho del bloque"
              v-model="form.ancho"
              :options="anchosBloque"
              expanded
            />
          </div>
          <div class="column is-12">
            <select-base
              rules="required"
              label="Tipo de gráfico"
              v-model="form.tipo_grafico"
              :options="tiposGraficos"
              expanded
            />
          </div>
          <div class="column is-12">
            <b-field>
              <b-checkbox v-model="showDateFilter">
                <span class="is-italic">Usar fecha para el filtrado</span>
              </b-checkbox>
            </b-field>
          </div>
          <div class="column is-12" v-show="showDateFilter">
            <date-picker-base
              :rules="showDateFilter ? `required` : ``"
              label="Mostrar entre las fechas"
              range
              :mobile-native="false"
              v-model="form.rango_fechas"
            />
          </div>
          <div class="column is-12">
            <switch-base
              label="Aceptar filtros externos?"
              v-model="form.filtros_externos"
            />
          </div>
        </div>
      </div>
      <div class="column is-4">
        <p>Arrástra el campo al cuadrante correspondiente:</p>
        <TreeCampos />
      </div>
      <div class="column is-4">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <TreeFilas v-model="form.columnas" title="Dimesión (Y)" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <TreeFilas v-model="form.filas" title="Dimesión (X)" />
          </div>
          <div class="column">
            <TreeValores v-model="form.valores" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="$emit('oncancel')"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );
// 
import TreeCampos from "../campos/TreeCampos.vue"
import TreeValores from "../valores/TreeValores.vue"
import TreeFilas from "../filas/TreeFilas.vue"
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  components: {
    TreeCampos,
    TreeValores,
    TreeFilas,
  },
  data() {
    return {
      loading: false,
      showDateFilter: false,
    };
  },
  computed:{
    anchosBloque(){
      return this.$store.getters[`${name_store}/anchosBloque`]
    },
    tiposGraficos(){
      return this.$store.getters[`${name_store}/tiposGraficos`]
    },
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  watch:{
    "form.id":{
      handler(e){
        if(e && this.form.rango_fechas.length){
          this.showDateFilter = true
        }
        if(!e) this.defaultData()
      },
      immediate: true,
    }
  },
  methods: {
    defaultData(){
      this.$set(this.form, "nombre", `Gráfico sin título`)
      this.$set(this.form, "ancho", 2)
      this.$set(this.form, "tipo_grafico", 3)
      this.$set(this.form, "comparar", ``)
      this.$set(this.form, "rango_fechas", [])
      this.$set(this.form, "filas", [])
      this.$set(this.form, "valores", [])
      this.$set(this.form, "columnas", [])
      this.$set(this.form, "filtros_externos", true)
      this.$set(this.form, "tipo", "grafico")
      if(this.$refs.observer){
        this.$refs.observer.reset()
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      if(!this.form.filas.length){
        return this.$store.dispatch(
          "app/setToastWarning",
          `Arraste un registro a la sección "FILAS"`
        );
      }
      if(!this.form.valores.length){
        return this.$store.dispatch(
          "app/setToastWarning",
          `Arraste almenos un registro a la sección "VALORES"`
        );
      }
      this.loading = true;
      const row = await this.$store.dispatch(`${name_store}/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar el registro: "${row.nombre}"`
        );
        if(!this.form.id) {
          this.defaultData()
        }
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>