<template>
  <div>
    <p class="has-text-weight-bold">Métricas</p>
    <draggable 
      tag="ul" 
      class="drag-group" 
      v-model="list"
      group="people" 
      ghost-class="drag-ghost" >
      <li 
        class="drag-item" 
        v-for="(element,k) in list" 
        :key="`${element.id}_${k}`">
          {{element.newLabel}} 
          <span @click.prevent="handleClick(element)" class="is-clickable has-text-info">
            <b-icon icon="info-circle" />
          </span>
      </li>
    </draggable>
    <ModalForm 
      v-model="form"
      :show="showModal"
      @modal="showModal = !showModal"
      @cancel="handleClose" />
    <ModalFormCustom 
      v-model="form"
      :show="showModal2"
      @modal="showModal2 = !showModal2"
      @cancel="handleClose" />
  </div>
</template>

<script>
import ModalForm from "./Modal.vue"
import ModalFormCustom from "@/modules/entitie/components/ModalVariableFormula.vue"
export default {
  props:{
    value: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ModalForm,
    ModalFormCustom,
  },
  data(){
    return {
      showModal: false,
      showModal2: false,
      form : {}
    }
  },
  computed:{
    list:{
      get(){
        return this.value || []
      },
      set(y){
        const e = y.filter(({type}) => type === "number" || type === "custom")
        if(e.length){
          const index = e.findIndex(r => !r.newLabel)
          if(index >= 0) {
            e[index].newLabel = `${!e[index].distinct && e[index].type === 'number' ? `Suma de` : ``} ${e[index].label}`
          }
        }
        this.$emit("input", [...e])
      }
    }
  },
  methods:{
    handleClick(e){
      if(e.type === `custom`){
        this.form = {...e}
        this.showModal2 = true
      } else {
        this.form = {
          id: e.id,
          label: e.label,
          etiqueta: e.newLabel,
          resumir: e.aggregateFunc,
          distinct: e.distinct,
          type: e.type,
        }
        this.showModal = true
      }
    },
    handleClose(){
      const index = this.list.findIndex(r => r.id === this.form.id)
      if(index >= 0){
        if(this.form.type === `custom`){
          this.list.splice(index, 1, this.form)
        } else {
          const f = JSON.parse( JSON.stringify(this.list[index]) )
          f.newLabel = this.form.etiqueta
          if(f.type === "number"){
            f.aggregateFunc = this.form.resumir
          }
          this.list.splice(index, 1, f)
        }
      }
    }
  }
}
</script>